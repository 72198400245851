const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.macquarie.forwoodsafety.com',
    USER_TOKEN_URL: 'https://ekts4ykuf7.execute-api.eu-west-1.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://2wwk25g0i5.execute-api.eu-west-1.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'admin-container-1.10.0',
    HOSTNAME: 'https://admin.macquarie.forwoodsafety.com',
    COMPANY_MICROFRONTEND_URL: 'https://company.macquarie.forwoodsafety.com',
    PHYSICAL_LOCATION_MICROFRONTEND_URL: 'https://locations.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://nycrtszsrd.execute-api.eu-west-1.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
